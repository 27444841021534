<template>
	<div class="">
		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane :label="$t('i18nn_5c98d407627f383d')" name="first">
				<BillHomeStatisticCharts @initDet="initDetQuery"></BillHomeStatisticCharts>
			</el-tab-pane>
			<el-tab-pane :label="$t('i18nn_5203265398bc2ae1')" name="second">
				<BillManage :parm="detQuery"></BillManage>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import BillHomeStatisticCharts from '@/components/StorageCenter/FinanceMana/BillHome/BillHomeStatisticCharts.vue';
	import BillManage from '@/components/StorageCenter/FinanceMana/BillManage.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			BillHomeStatisticCharts,
			BillManage
		},
		watch: {
			// $route: {
			// 	handler(newVal, oldVal) {
			// 		//判断newVal有没有值监听路由变化
			// 		if (newVal != oldVal) {
			// 			location.reload()
			// 		}
			// 	},
			// 	deep: true
			// }
		},
		data() {
			return {
				activeName: 'first',
				detQuery:{}
			};
		},
		activated() {

		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initDetQuery(query){
				console.log('query',query);
				if(query.whNo && query.daterange){
					this.detQuery = query;
					this.activeName = "second";
				} else {
					this.$message.warning(this.$t('tips.queryErrorFailed'));
				}
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// @import url(@/assets/css/service_mod.less);
</style>
