<template>
	<!-- 导入excel -->
	<!-- <el-dialog :title="'SKU库存趋势统计'" append-to-body :close-on-click-modal="false" :visible.sync="dialogShow" width="1100px" top="0"> -->
	<!-- <div style="margin-top: 10px;" v-loading="loading"> -->
	<!-- <el-card> -->
	<!-- <div slot="header" class="">
          <h3>
            <i class="el-icon-notebook-2"></i>
            <span>SKU库存趋势统计</span>
          </h3>
        </div> -->
	<div><div ref="chartsMain" style="width: 1200px; height: 300px;"></div></div>
	<!-- </el-card> -->
	<!-- </div> -->

	<!-- <div slot="footer" class="dialog-footer"> -->
	<!-- <el-button type="primary" plain @click="dialogShow = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button> -->
	<!-- <el-button type="primary" @click="printerAction2()">{{$t('i18nn_886072e393710563')}}</el-button> -->
	<!-- </div> -->
	<!-- </el-dialog> -->
</template>
<script>
// import * as echarts from 'echarts';
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	DatasetComponentOption,
	TransformComponent,
	ToolboxComponent,
	LegendComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	TransformComponent,
	BarChart,
	LabelLayout,
	UniversalTransition,
	CanvasRenderer,
	ToolboxComponent,
	LegendComponent
]);

export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	props: {
		openTime: {
			// default: function() {
			//   return '';
			// },
			// type: String
		},
		parm: {
			default: function() {
				return {
					// userId: '', //"用户ID",
					// goodsSKu: '', //this.$t('i18nn_74ed28f373ea14da'),
					// whNo: '' //this.$t('i18nn_5acbec83efb27445')
				};
			},
			type: Object
		},
		chartData: {
			default: function() {
				return {
					title: '',
					// type: '',
					XData: [],
					YData1: []
				};
			},
			type: Object
		}
		// fileList: {
		//   default: function() {
		//     return [];
		//   },
		//   type: Array
		// }
	},
	components: {},
	data() {
		return {
			// dialogShow: false,
			loading: false
		};
	},
	watch: {
		openTime: function(newVal, oldVal) {
			console.log('openTime');
			//初始化
			// this.dialogShow = true;
			this.initData();
		}
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.initData();
	},
	methods: {
		initData() {
			this.$nextTick(() => {
				console.log('chartData', this.chartData);
				this.initCharts(this.chartData.title, this.chartData.XData, this.chartData.YData1);
			});
			// this.getDetData();
		},

		initCharts(title, XData, YData1) {
			// console.log('initCharts', XData, YData1);
			// 基于准备好的dom，初始化echarts实例
			var myChart = echarts.init(this.$refs.chartsMain, null, { renderer: 'svg' });
			// var myChart = echarts.init(document.getElementById('chartsMain'));
			myChart.clear();
			myChart.resize();
			let option = {
				title: {
					// text: title
				},
				tooltip: {
					trigger: 'axis',
					    axisPointer: {
					      // Use axis to trigger tooltip
					      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					    }
				},
				grid: {
				    left: '3%',
				    right: '4%',
				    bottom: '3%',
				    containLabel: true
				  },
				// toolbox: {
				// 	feature: {
				// 		// dataZoom: {
				// 		// 	yAxisIndex: 'none'
				// 		// },
				// 		// dataView: { readOnly: false },
				// 		// magicType: { type: ['line', 'bar'] },
				// 		// restore: {},
				// 		saveAsImage: {}
				// 	}
				// },
				// legend: {
				// 	data: [this.$t('i18nn_684a2afb069b6016'), this.$t('i18nn_6b1945319eb18d5f')]
				// },
				grid: {
					left: '3%',
					right: '5%',
					top: '15%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					name: this.$t('i18nn_184333c81babf2f1'),
					type: 'category',
					//    boundaryGap: false,
					axisLabel: {
						interval: 'auto',
						rotate: '30'
					},
					// formatter: function (value, index) {
					//     return value.replace(' ', '\n');
					// },
					data: XData
				},
				// yAxis: {
				// 	// name: this.$t('i18nn_de53ab10322cd38a'),
				// 	// type: 'value',
				// },
				yAxis: {
					name: this.$t('i18nn_16658a855f07d171'),
					type: 'value',
					// triggerEvent: true
					// boundaryGap: [0, '10%']
				},
				series: [
					{
						// name: this.$t('i18nn_684a2afb069b6016'),
						type: 'bar',
						label: {
							show: true,
							// color: "rgba(0, 64, 255, 1)",
							// fontSize: 18,
							// backgroundColor: "rgba(192, 223, 248, 1)",
							// borderColor: "rgba(0, 64, 255, 1)",
							// borderWidth: 1,
							// padding: [5, 10, 5, 10]
							position: 'top'
						},
						// symbol: "roundRect",
						// symbolSize : 20,
						// itemStyle:{
						// 	borderWidth: 10
						// },
						// areaStyle: {},
						data: YData1
						// markLine:{
						// 	emphasis :{
						// 		disabled:false
						// 	}
						// }
						// markPoint: {
						// 	data: [
						// 		{ type: 'max', name: 'Max' },
						// 		{ type: 'min', name: 'Min' }
						// 	]
						// }
					},
					// {
					// 	name: this.$t('i18nn_6b1945319eb18d5f'),
					// 	type: 'line',
					// 	label: {
					// 		show: true,
					// 	},
					// 	// position: 'bottom',
					// 	data: YData2
					// }
				]
			};
			//按月统计的
			// if ('1' == this.chartData.type) {
			// 	// 一件代发的
			// 	option.series[0].label.backgroundColor = 'rgba(192, 223, 248, 1)';
			// 	option.series[0].label.padding = [5, 10, 5, 10];
			// 	option.series[1].label.padding = [0, 10, 0, 10];
			// }
			// 绘制图表
			myChart.setOption(option);
			
			//按月统计的
			// if ('1' == this.chartData.type) {
			// 	myChart.on('click', param => {
			// 		console.log('click', param, param.data); //这里根据param填写你的跳转逻辑
			// 		// if (0 === param.componentIndex) {
			// 		//按月统计
			// 		this.$emit('clickMon', param.name);
			// 		// }
			// 	});
			// }
			// myChart.on('mouseover', function(param) {
			// 	console.log('mouseover', param, param.data); //这里根据param填写你的跳转逻辑
			// });
		}

		// getDetData() {
		// 	this.loading = true;
		// 	this.$http
		// 		.put(this.$urlConfig.WhReportSkuCharts, {
		// 			userId: this.parm.userId, //"用户ID",
		// 			goodsSku: this.parm.goodsSku, //this.$t('i18nn_74ed28f373ea14da'),
		// 			whNo: this.parm.whNo //this.$t('i18nn_5acbec83efb27445')
		// 		})
		// 		.then(({ data }) => {
		// 			console.log('查询，请求成功');
		// 			console.log(data);
		// 			this.loading = false;
		// 			let XData = [],
		// 				YData1 = [],
		// 				YData2 = [],
		// 				YData3 = [],
		// 				YData4 = [];
		// 			if (data.rows) {
		// 				data.rows.forEach(item => {
		// 					XData.push(item.reportDate.substring(0, 10));
		// 					YData1.push(item.stock);
		// 					// YData2.push(item.whDay);
		// 					// YData3.push(item.totalWeight);
		// 					YData4.push(item.totalVol);
		// 				});
		// 			}

		// 			this.$nextTick(() => {
		// 				this.initCharts(this.parm.goodsSku + '\n' + this.parm.whNo, XData, YData1, YData2, YData3, YData4);
		// 			});
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.errorData'));
		// 			this.loading = false;
		// 			this.$message.error('查询失败,请重试！');
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
