<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
				<div class="tableConTable">
					<!-- <div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_5c98d407627f383d')}}</span>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div> -->

					<div>
						<div class="filterCon" style="">
							<!-- <el-row type="flex" justify="end"> -->
								<!-- <el-col :span="24"> -->
									<ul class="filterConList">
										<li>
											<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
											<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
										</li>
										<li>
											<span><i class="red">*</i>{{$t('i18nn_d42355e6aef72227')}}</span>
											<el-date-picker v-model="filterData.daterange" size="small" type="daterange"
												align="right" :clearable="true" unlink-panels range-separator="-"
												start-placeholder="start date" end-placeholder="end date"
												:picker-options="pickerOptions" format="yyyy-MM-dd"
												value-format="yyyy-MM-dd" @change="initData()"></el-date-picker>
										</li>
										<!-- <li class="">
											<span>{{ $t('i18nn_c944a6686d996ab3') }}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li> -->

										<li>
											<el-button icon="el-icon-search" size="small" type="primary"
												@click="initData()">{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button>
										</li>
									</ul>
								<!-- </el-col> -->
							<!-- </el-row> -->
						</div>
					</div>

					<div>
						<el-card style="margin-bottom: 10px;">
							<div slot="header">
								<strong>{{$t('i18nn_68ceeb72fa5cbecf')}}</strong>
								<span>
									<el-tag :type="'primary'" effect="plain">
										{{ FeeAllTotal }}
									</el-tag>
								</span>
							</div>
							<div>
								<BillBarChartsAll :openTime="ChartsAllDataOpenTime" :parm="ChartsAllDataParm"
									:chartData="ChartDataAll"></BillBarChartsAll>
							</div>
						</el-card>
						
						<div style="margin-bottom: 10px;">
							<!-- <el-tabs type="card"> -->
								<!-- <el-tab-pane :label="$t('i18nn_0d35fa57b3aab0f7')">
									<el-table ref="multipleTable" :data="tableDataCus" stripe :border="true"
										:max-height="$store.state.tableMaxHeight4"
										 v-loading="loading_cus"
										style="width: 100%;margin-bottom: 10px;" size="small">
										<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
											:label="$t('7b1c2b1adc920d9c')"></el-table-column>
										<el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>
										<el-table-column prop="totalFee" :label="$t('i18nn_14b9b51812811e15')">
											<template slot-scope="scope">
												<div><strong>{{scope.row.totalFee}}</strong></div>
											</template>
										</el-table-column>
										
										<el-table-column prop="SCCTotalFee" :label="$t('i18nn_21e42bbe9894aafc')"></el-table-column>
										<el-table-column prop="SDSTotalFee" :label="$t('i18nn_194d733acdedb737')"></el-table-column>
										<el-table-column prop="STFTotalFee" :label="$t('i18nn_6b1945319eb18d5f')"></el-table-column>
										<el-table-column prop="SDSOutTotalFee" :label="$t('i18nn_9695030363addb97')"></el-table-column>
										<el-table-column prop="storeTotalFee" :label="$t('i18nn_5151fd7875058ddf')"></el-table-column>
										<el-table-column prop="surchargeTotalFee" :label="$t('i18nn_636d7192b222277c')"></el-table-column>
										
										<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="180px">
											<template slot-scope="scope">
												<div>
													<el-button size="small" type="primary"
														@click="toWhDetByCus($event,scope.row)"
														icon="el-icon-refresh-right">{{$t('i18nn_e19ca5d2e0c83887')}}</el-button>
												</div>
											</template>
										</el-table-column>
									</el-table>
									
									<el-card style="margin-bottom: 10px;" shadow="never" v-loading="loading_cusByWh">
										<div slot="header">
											<strong>{{$t('i18nn_ac53fd8c761abf7e')}}</strong>&nbsp;
											<span>{{$t('i18nn_18d7892498938435')}}<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy></span>
										</div>
										<div>
											<el-table ref="multipleTable2" :data="tableDataCusByWh" stripe :border="true"
											
											:max-height="$store.state.tableMaxHeight4"
												style="width: 100%" size="small">
												<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
													:label="$t('7b1c2b1adc920d9c')"></el-table-column>
													<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
												<el-table-column prop="totalFee" :label="$t('i18nn_14b9b51812811e15')">
													<template slot-scope="scope">
														<div><strong>{{scope.row.totalFee}}</strong></div>
													</template>
												</el-table-column>
												
												<el-table-column prop="SCCTotalFee" :label="$t('i18nn_21e42bbe9894aafc')"></el-table-column>
												<el-table-column prop="SDSTotalFee" :label="$t('i18nn_194d733acdedb737')"></el-table-column>
												<el-table-column prop="STFTotalFee" :label="$t('i18nn_6b1945319eb18d5f')"></el-table-column>
												<el-table-column prop="SDSOutTotalFee" :label="$t('i18nn_9695030363addb97')"></el-table-column>
												<el-table-column prop="storeTotalFee" :label="$t('i18nn_5151fd7875058ddf')"></el-table-column>
												<el-table-column prop="surchargeTotalFee" :label="$t('i18nn_636d7192b222277c')"></el-table-column>
												
												<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="180px">
													<template slot-scope="scope">
														<div>
															<el-button size="small" type="primary"
																@click="toCusByWhDet($event,scope.row)" icon="el-icon-right">{{$t('i18nn_2f281726defd00db')}}</el-button>
														</div>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-card>
									
								</el-tab-pane> -->
								<!-- <el-tab-pane :label="$t('i18nn_cf84be5a2f8c584b')"> -->
									<el-card>
										<div slot="header">
											<strong>{{$t('i18nn_cf84be5a2f8c584b')}}</strong>
										</div>
										<div v-loading="loading_wh" style="margin-bottom: 10px;">
											<!-- <el-tabs type="border-card" v-model="activeName"> -->
												<!-- <el-tab-pane :label="$t('i18nn_16aa273f2941c9b5')" name="first"> -->
													<el-table ref="multipleTable3" :data="tableDataWh" stripe :border="true" 
														:max-height="$store.state.tableMaxHeight4"
														style="width: 100%" size="small">
														<el-table-column type="index" fixed="left" width="50" align="center"
															:label="$t('7b1c2b1adc920d9c')"></el-table-column>
														<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
														<el-table-column prop="totalFee" :label="$t('i18nn_14b9b51812811e15')">
															<template slot-scope="scope">
																<div><strong>{{scope.row.totalFee}}</strong></div>
															</template>
														</el-table-column>
														
														<el-table-column prop="SCCTotalFee" :label="$t('i18nn_21e42bbe9894aafc')"></el-table-column>
														
														<el-table-column prop="SDSTotalFee" :label="$t('i18nn_194d733acdedb737')"></el-table-column>
														
														
														<el-table-column prop="SDSOutTotalFee" :label="$t('i18nn_9695030363addb97')"></el-table-column>
														
														<el-table-column prop="STFTotalFee" :label="$t('i18nn_6b1945319eb18d5f')"></el-table-column>
														
														
														
														<el-table-column prop="FTRTotalFee" :label="$t('i18nn_21d58958f280d153')"></el-table-column>
														<el-table-column prop="TSCCTotalFee" :label="$t('i18nn_56b48b3b6de43bde')"></el-table-column>
														<el-table-column prop="STRTotalFee" :label="$t('i18nn_e69eafd9946571ed')"></el-table-column>
														
														<el-table-column prop="SRETotalFee" :label="$t('i18nn_5ddc19a148f5e5c1')">
														</el-table-column>
														
														<el-table-column prop="storeTotalFee" :label="$t('i18nn_5151fd7875058ddf')"></el-table-column>
														<el-table-column prop="surchargeTotalFee" :label="$t('i18nn_636d7192b222277c')"></el-table-column>
														<el-table-column prop="ADDVSTotalFee" :label="$t('i18nn_f80dedf7c601059f')">
														</el-table-column>
														<el-table-column prop="PCPUTotalFee" :label="$t('i18nn_5b177a1b00c63503')">
														</el-table-column>
														<el-table-column prop="TRUCKTotalFee" :label="$t('i18nn_9a716c393c296ea8')">
														</el-table-column>
														
														
														<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="180px">
															<template slot-scope="scope">
																<div>
																	<!-- <el-button size="small" type="primary"
																		@click="toWhDet($event,scope.row)"
																		icon="el-icon-refresh-right">{{$t('i18nn_e19ca5d2e0c83887')}}</el-button> -->
																		<el-button size="small" type="primary"
																			@click="toWhByCusDet($event,scope.row)" icon="el-icon-right">{{$t('i18nn_2f281726defd00db')}}</el-button>
																</div>
															</template>
														</el-table-column>
													</el-table>
												<!-- </el-tab-pane> -->
												<!-- <el-tab-pane :label="$t('i18nn_588fde1c3de565ab')" name="second">
													<BillBarChartsWh :openTime="ChartsWhDataOpenTime" :parm="ChartsWhDataParm"
														:chartData="ChartDataWh"></BillBarChartsWh>
												</el-tab-pane> -->
											<!-- </el-tabs> -->
										</div>
									</el-card>
									<!-- <el-card style="margin-bottom: 10px;" shadow="never" v-loading="loading_table">
										<div slot="header">
											<strong>{{$t('i18nn_ac53fd8c761abf7e')}}</strong>&nbsp;
											<span>{{$t('i18nn_c944a6686d996ab3')}}<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect></span>
										</div>
										<div>
											<el-table ref="multipleTable4" :data="tableData" stripe :border="true"
											
											:max-height="$store.state.tableMaxHeight4"
												style="width: 100%" size="small">
												<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
													:label="$t('7b1c2b1adc920d9c')"></el-table-column>
												<el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>
												<el-table-column prop="totalFee" :label="$t('i18nn_14b9b51812811e15')">
													<template slot-scope="scope">
														<div><strong>{{scope.row.totalFee}}</strong></div>
													</template>
												</el-table-column>
												
												<el-table-column prop="SCCTotalFee" :label="$t('i18nn_21e42bbe9894aafc')"></el-table-column>
												<el-table-column prop="SDSTotalFee" :label="$t('i18nn_194d733acdedb737')"></el-table-column>
												<el-table-column prop="STFTotalFee" :label="$t('i18nn_6b1945319eb18d5f')"></el-table-column>
												<el-table-column prop="SDSOutTotalFee" :label="$t('i18nn_9695030363addb97')"></el-table-column>
												<el-table-column prop="storeTotalFee" :label="$t('i18nn_5151fd7875058ddf')"></el-table-column>
												<el-table-column prop="surchargeTotalFee" :label="$t('i18nn_636d7192b222277c')"></el-table-column>
												
												<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="180px">
													<template slot-scope="scope">
														<div>
															<el-button size="small" type="primary"
																@click="toWhByCusDet($event,scope.row)" icon="el-icon-right">{{$t('i18nn_2f281726defd00db')}}</el-button>
														</div>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-card> -->
								<!-- </el-tab-pane> -->
							<!-- </el-tabs> -->
						</div>
						
						
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import whExcelCustdom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';

	// import DialogInStSkuCharts from '@/components/WarehouseCenter2/statement/DialogInStSkuCharts.vue';

	import BillBarChartsAll from '@/components/StorageCenter/FinanceMana/BillHome/BillBarChartsAll.vue';
	// import BillBarChartsWh from '@/components/StorageCenter/FinanceMana/BillHome/BillBarChartsWh.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			SelAgentUser,
			// WhPlace,
			// cusSelFuzzy,
			whNoSelect,
			// whExcelCustom,
			// DialogInStSkuCharts,
			BillBarChartsAll,
			// BillBarChartsWh
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// popTest : false,
				pickerOptions: this.$PickerDate.pickerOptions_1(),
				FeeAllTotal: '',
				ChartsAllDataOpenTime: '',
				ChartsAllDataParm: {},
				ChartDataAll: {},

				// ChartsWhDataOpenTime: '',
				// ChartsWhDataParm: {},
				// ChartDataWh: {},
				
				loading_wh: false,
				tableDataWh: [],

				tableData: [],
				loading_table: false,
				
				loading_cus: false,
				tableDataCus:[],
				
				tableDataCusByWh: [],
				loading_cusByWh: false,

				loading: false,
				loading_load: false,
				
				activeName: 'first',

				selectOption: {
					wh_no: []

				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					agentUser: '',
					// "accountPeriod":"",
					// daterange: [],
					daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
						.$Utils.fomatterDate_YYYYMMdd(new Date())
					],

					goodsSku: '',
					whNo: '',
					userId: ''
					// goodsSku: '',
					// goodsName:""
				}
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoData();
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				// this.getPageData();
				// this.getPageData2();

				if (this.filterData.daterange.length < 2) {
					this.$message.warning(this.$t('tips.Please_Sel_date'));
					return;
				}
				this.ChartsAllDataParm = {};
				this.ChartDataAll = {
					title: this.$t('i18nn_5c98d407627f383d'),
					XData: [],
					YData1: [],
				};
				this.ChartsAllDataOpenTime = new Date().getTime();
				
				this.getAllFeeCountData();
				// this.ChartsWhDataParm = {};
				// this.ChartDataWh = {
				// 	title: this.$t('i18nn_cf84be5a2f8c584b'),
				// 	XData: [],
				// 	TotalData: [],
				// 	seriesData: [],
				// };
				// this.ChartsWhDataOpenTime = new Date().getTime();
				
				this.tableDataWh = [];
				this.getAllFeeWhData();
				
				// this.tableDataCus = [];
				// this.getAllFeeCusData();
				
				this.tableData = [];
				this.$nextTick(() => {
					// this.$refs.multipleTable.doLayout();
				});
				// this.getDicData();
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				// this.serAllFeeWhAndCusData();
				this.initData();
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.userId = data.userId;
			// 	this.serAllFeeCusAndWhData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.serAllFeeWhAndCusData();
				// this.filterData.userId = data.userId;
				this.initData();
			},

			//所有费用汇总统计
			getAllFeeCountData() {
				let filterData = this.pageFilterData();
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhBillAllFeeTotal, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code && data.data) {
							this.FeeAllTotal = data.data.totalFee;
							if (data.data.feeRecrods && data.data.feeRecrods.length > 0) {
								let charData = data.data.feeRecrods;
								this.initChar(charData);
							}
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading_load = false;
					});
			},
			//按仓库费用汇总统计
			getAllFeeWhData() {
				let filterData = this.pageFilterData();
				this.loading_wh = true;
				this.$http
					.put(this.$urlConfig.WhBillAllFeeAndWh, filterData)
					.then(({
						data
					}) => {
						this.loading_wh = false;
						if (200 == data.code && data.rows) {
							this.tableDataWh = data.rows;
							// let charData = data.rows;
							// this.initChar2(charData);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading_wh = false;
					});
			},
			//按客户费用汇总统计
			// getAllFeeCusData() {
			// 	let filterData = this.pageFilterData();
			// 	this.loading_cus = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhBillAllFeeAndCus, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_cus = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.tableDataCus = data.rows;
			// 				let charData = data.rows;
			// 				// this.initChar2(charData);
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_0c72f3afd482899d'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('按客户汇总数据查询失败！');
			// 			this.loading_cus = false;
			// 		});
			// },
			//跳转到账单
			toWhByCusDet(event, row) {
				event.stopPropagation();
				let query = {
					// cusId: row.userId,
					whNo: row.whNo,
					daterange: this.filterData.daterange.join(",")
				}
				console.log('query',query);
				this.$emit("initDet",query);
			},
			//跳转到账单
			// toCusByWhDet(event, row) {
			// 	event.stopPropagation();
			// 	let query = {
			// 		cusId: this.filterData.userId,
			// 		whNo: row.whNo,
			// 		daterange: this.filterData.daterange.join(",")
			// 	}
			// 	this.$emit("initDet",query);
			// },
			//刷新客户汇总
			// toWhDet(event, row) {
			// 	event.stopPropagation();
			// 	this.filterData.whNo = row.whNo;
			// 	this.$refs.whNoSelect.init(row.whNo);
			// 	this.serAllFeeWhAndCusData();
			// 	// let whNo = this.filterData.whNo;
			// 	// this.getAllFeeWhAndCusData(whNo);
			// },

			//搜索按客户汇总数据
			// serAllFeeWhAndCusData() {
			// 	let whNo = this.filterData.whNo;
			// 	this.getAllFeeWhAndCusData(whNo);
			// },
			//按仓库的客户费用汇总统计
			// getAllFeeWhAndCusData(whNo) {
			// 	let filterData = this.pageFilterData();
			// 	filterData.whNo = whNo;
			// 	this.loading_table = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhBillAllFeeAndWhByCus, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_table = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.tableData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_44c439345b1a2d97'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('按仓库的客户汇总数据查询失败！');
			// 			this.loading_table = false;
			// 		});
			// },
			//刷新客户汇总
			// toWhDetByCus(event, row) {
			// 	event.stopPropagation();
			// 	this.filterData.userId = row.userId;
			// 	// this.$refs.cusSelFuzzy.init(row.userId);
			// 	this.serAllFeeCusAndWhData();
			// 	// let userId = this.filterData.userId;
			// 	// this.getAllFeeCusAndWhData(userId);
			// },
			// //搜索按客户汇总数据
			// serAllFeeCusAndWhData() {
			// 	let userId = this.filterData.userId;
			// 	this.getAllFeeCusAndWhData(userId);
			// },
			// //按客户的仓库费用汇总统计
			// getAllFeeCusAndWhData(userId) {
			// 	let filterData = this.pageFilterData();
			// 	filterData.userId = userId;
			// 	this.loading_cusByWh = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhBillAllFeeAndCusByWh, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_cusByWh = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.tableDataCusByWh = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_cf698047bdf5efae'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('按客户的仓库汇总数据查询失败！');
			// 			this.loading_cusByWh = false;
			// 		});
			// },
			//分页的筛选项数据
			pageFilterData() {
				console.log('daterange', this.filterData.daterange);
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					// this.$message.warning(this.$t('tips.Please_Sel_date'));
				}
				// startD endD
				return {
					// userId: this.filterData.userId ? this.filterData.userId : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					opTimeStart: startTime ? startTime : null,
					opTimeEnd: endTime ? endTime : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null
				};
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},
			//总数据
			initChar(data) {
				this.ChartsAllDataParm = {};
				let XData = [],
					YData1 = [];
				data.forEach(item => {
					if ('SCC' == item.feeType) {
						XData.push(this.$t('i18nn_21e42bbe9894aafc'));
					} else if ('TSCC' == item.feeType) {
						XData.push(this.$t('i18nn_1f1bd51a9a045025'));
					} else if ('SDS' == item.feeType) {
						XData.push(this.$t('i18nn_194d733acdedb737'));
					} else if ('STF' == item.feeType) {
						XData.push(this.$t('i18nn_6373cb1d1204d580'));
					} else if ('STR' == item.feeType) {
						XData.push(this.$t('i18nn_65fe75ea422bdc50'));
					} else  if ('SDSOP020' == item.feeType) {
						XData.push(this.$t('i18nn_9695030363addb97'));
					} else if ('WHF001' == item.feeType) {
						XData.push(this.$t('i18nn_5151fd7875058ddf'));
					} else if ('ADDVS' == item.feeType) {
						XData.push(this.$t('i18nn_f80dedf7c601059f'));
					} else  if ('surchargeFee' == item.feeType) {
						XData.push(this.$t('i18nn_636d7192b222277c'));
					} else  if ('SRE' == item.feeType) {
						XData.push(this.$t('i18nn_5ddc19a148f5e5c1'));
					} else  if ('FTR' == item.feeType) {
						XData.push(this.$t('i18nn_21d58958f280d153'));
					} else  if ('PCPU' == item.feeType) {
						XData.push(this.$t('i18nn_5b177a1b00c63503'));
					} else  if ('TRUCK' == item.feeType) {
						XData.push(this.$t('i18nn_9a716c393c296ea8'));
					} else {
						XData.push(item.feeType);
					}

					YData1.push(item.feeTotal);
				});
				// data2.forEach(item => {
				// 	YData2.push(item.totalCount);
				// });
				this.ChartDataAll = {
					title: this.$t('i18nn_5c98d407627f383d'),
					// type: '1',
					XData: XData,
					YData1: YData1,
				}
				this.ChartsAllDataOpenTime = new Date().getTime();
				
			},
			//按仓库汇总数据
			// initChar2(data) {
			// 	this.ChartsWhDataParm = {};
				
			// 	let XData = [],
			// 		TotalData = [],
			// 		seriesData = [{
			// 			name: this.$t('i18nn_21e42bbe9894aafc'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_194d733acdedb737'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_6b1945319eb18d5f'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_9695030363addb97'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_5151fd7875058ddf'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_636d7192b222277c'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_1f1bd51a9a045025'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_65fe75ea422bdc50'),
			// 			data: []
			// 		}, {
			// 			name: this.$t('i18nn_f80dedf7c601059f'),
			// 			data: []
			// 		}];

			// 	data.map(item => {
			// 		XData.push(item.whNo);
			// 		TotalData.push(item.totalFee);
					
			// 		// <el-table-column prop="SCCTotalFee" :label="$t('i18nn_21e42bbe9894aafc')"></el-table-column>
			// 		// <el-table-column prop="SDSTotalFee" :label="$t('i18nn_194d733acdedb737')"></el-table-column>
			// 		// <el-table-column prop="STFTotalFee" :label="$t('i18nn_6b1945319eb18d5f')"></el-table-column>
			// 		// <el-table-column prop="SDSOutTotalFee" :label="$t('i18nn_9695030363addb97')"></el-table-column>
			// 		// <el-table-column prop="storeTotalFee" :label="$t('i18nn_5151fd7875058ddf')"></el-table-column>
			// 		// <el-table-column prop="surchargeTotalFee" :label="$t('i18nn_636d7192b222277c')"></el-table-column>
					
			// 		seriesData[0].data.push(item.SCCTotalFee);
			// 		seriesData[1].data.push(item.SDSTotalFee);
			// 		seriesData[2].data.push(item.STFTotalFee);
			// 		seriesData[3].data.push(item.SDSOutTotalFee);
			// 		seriesData[4].data.push(item.storeTotalFee);
			// 		seriesData[5].data.push(item.surchargeTotalFee);
					
			// 		seriesData[6].data.push(item.TSCCTotalFee);
			// 		seriesData[7].data.push(item.STRTotalFee);
			// 		seriesData[8].data.push(item.ADDVSTotalFee);
			// 		// item.feeRecrods.map(item2 => {
			// 		// 	if ('SCC' == item2.feeType) {
			// 		// 		seriesData[0].data.push(item2.feeTotal)
			// 		// 	} else if ('SDS' == item2.feeType) {
			// 		// 		seriesData[1].data.push(item2.feeTotal)
			// 		// 	} else if ('STF' == item2.feeType) {
			// 		// 		seriesData[2].data.push(item2.feeTotal)
			// 		// 	} else if ('SDSOP020' == item2.feeType) {
			// 		// 		seriesData[3].data.push(item2.feeTotal)
			// 		// 	} else if ('WHF001' == item2.feeType) {
			// 		// 		seriesData[4].data.push(item2.feeTotal)
			// 		// 	} else if ('surchargeFee' == item2.feeType) {
			// 		// 		seriesData[5].data.push(item2.feeTotal)
			// 		// 	} else {

			// 		// 	}
			// 		// });
			// 	});
			// 	//只有数据长度一致才显示
			// 	// if (seriesData[0].data.length == seriesData[1].data.length &&
			// 	// 	seriesData[0].data.length == seriesData[2].data.length &&
			// 	// 	seriesData[0].data.length == seriesData[3].data.length &&
			// 	// 	seriesData[0].data.length == seriesData[4].data.length &&
			// 	// 	seriesData[0].data.length == seriesData[5].data.length
			// 	// ) {
			// 	// 	console.log(seriesData);
			// 	// } else {
			// 	// 	seriesData = [];
			// 	// }
			// 	// data.forEach(item => {
			// 	// 	XData.push(item.commitDate);
			// 	// 	YData1.push(item.dayCount);
			// 	// });
			// 	// data2.forEach(item => {
			// 	// 	YData2.push(item.dayCount);
			// 	// });
			// 	this.ChartDataWh = {
			// 		title: this.$t('i18nn_cf84be5a2f8c584b'),
			// 		XData: XData,
			// 		TotalData: TotalData,
			// 		seriesData: seriesData,
			// 	}
			// 	this.ChartsWhDataOpenTime = new Date().getTime();
			// },

			//点击按月统计
			// clickAll(val) {
			// 	this.getPageData2(val);
			// },

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_goods_fee_type'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询数据字典失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.inline-table-line-list {
		display: flex;
		flex-wrap: wrap;

		li {
			display: flex;
			width: 150px;
			padding: 0 10px;
		}
	}
</style>
<style scoped lang="less">
// @import url(@/assets/css/service_mod.less);
</style>
